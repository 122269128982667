.container {
  flex: 1;
}

.wide-container {
  margin-left: 1em;
  margin-right: 1em;
}

header {
  padding-top: 0 !important;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1340px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1500px;
  }
}